// export const CONTRACT_ADDRESS = "0x73d6a45ee77942d371f970000f1f395129b558a2";
export const CONTRACT_ADDRESS = "0xc9D49A6aE72AB9F7B406591910210297C489348c";
export const CHAIN_ID = 56;
export const RPC_URL = "https://bsc-dataseed1.bnbchain.org";
// export const RPC_URL = "https://data-seed-prebsc-2-s3.bnbchain.org:8545";
export const FEES = 10; 
export const MULTICALL_ADDRESS = "0xed386Fe855C1EFf2f843B910923Dd8846E45C5A4";
// export const MULTICALL_ADDRESS = "0xa54fE4a3DbD1Eb21524Cd73754666b7E13b4cB18";
export const GAS_FEES = 0.001;
export const PROJECT_ID = "60772f0388ab28c22ea5e778feb9e02c"
export const BEANS_TO_HATCH_1BEAN = 1080000;
export const EXPLORE_URL = "https://bscscan.com"
export const WEEK_TIME = 259200;
export const ENDTIME = 1724076144
export const BASE_URL = "https://ultimate-bakedbeans.app/"